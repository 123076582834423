import { FC, useContext, useMemo } from 'react';
import { List, ListItem, ListItemButton, ListItemText, Radio, RadioGroup } from '@mui/material';
import styles from './RadioGroupButtons.module.scss';
import { Controller } from 'react-hook-form';
import { FormContext } from 'core/context/form.context'; // Import the SCSS module

export interface IRadioGroupOption {
  key: string;
  value: boolean;
}

interface RadioGroupButtonsProps {
  options: IRadioGroupOption[];
  name: string;
}

const RadioGroupButtons: FC<RadioGroupButtonsProps> = ({ options, name }) => {
  const memoizedOptions = useMemo(() => options, [options]);
  const { control } = useContext(FormContext);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <RadioGroup style={{ width: '100%' }} aria-label="Your plan" name="people" value={field.value}>
          <List className={styles.list}>
            {memoizedOptions.map(({ key, value: optionValue }) => (
              <ListItem key={key} className={styles.listItem}>
                <ListItemButton
                  sx={{
                    borderColor: field.value === optionValue ? '#007BFF' : '#d7d8da',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderRadius: '8px',
                  }}
                  onClick={() => field.onChange(optionValue)}
                  className={`${styles.listItemButton} ${field.value === optionValue ? styles.selected : ''}`}
                >
                  <ListItemText primary={key} />
                  <Radio value={optionValue} checked={field.value === optionValue} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </RadioGroup>
      )}
    />
  );
};

export default RadioGroupButtons;

import { FC, useRef, useState } from 'react';
import FormHeader from 'component/atoms/FormHeader';
import { Grid, InputLabel } from '@mui/material';
import ApartmentMediaUpload from 'component/organisms/ApartmentMediaUpload';
import { ICreateApartmentTunnel } from 'view/landlord/apartment/ApartmentCreateView';
import useApartment from 'core/hook/apartment.hook';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import { TEXT_COLOR } from 'component/atoms/typographie/Text';
import FormNavigation from 'component/molecules/FormNavigation';
import { ROUTES } from 'core/const/routes';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ApartmentDocumentUpload from 'component/organisms/ApartmentDocumentUpload';
import ApartmentLinksUpload from 'component/organisms/ApartmentLinksUpload';
import Card from 'component/atoms/Card';
import { PopupButton as CalendlyButton, PopupModal } from 'react-calendly';
import SplitLine from 'component/atoms/SplitLine';
import ApartmentLayoutUpload from 'component/organisms/ApartmentLayoutUpload';
import BackButton from 'component/atoms/Buttons/BackButton';
import useWindowSize from 'core/hook/windowsize.hook';

const ApartmentMediaForm: FC<ICreateApartmentTunnel> = ({ isTunnelView }) => {
  const { apartment } = useApartment();
  const { t: v } = useTranslation('common', { keyPrefix: 'view.ServicesView' });
  const calendlyButtonRef = useRef(null);
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);
  const { t: r } = useTranslation('routes');
  const { t } = useTranslation('common');

  const navigate = useNavigate();
  const { isSmallerMd } = useWindowSize();

  const handleSubmit = () => {
    if (isTunnelView) {
      navigate(r(ROUTES.landlordRoutes.apartment.create.desiredTenant.path).replace(':apartmentId', apartment?.id));
    } else {
      navigate(r(ROUTES.landlordRoutes.apartment.overview.path).replace(':apartmentId', apartment?.id));
    }
  };

  return (
    <div style={{ paddingBottom: 80 }}>
      <PopupModal
        url={process.env.REACT_APP_CALENDLY_3D_TOUR_BOOKING_LINK}
        onModalClose={() => setIsCalendlyOpen(false)}
        open={isCalendlyOpen}
        rootElement={document.getElementById('root')}
      />
      <CalendlyButton
        url={process.env.calendly3DTourBookingLink}
        rootElement={document.getElementById('root')}
        text=""
        className={'hidden'}
        ref={calendlyButtonRef}
      />
      {!isTunnelView && !isSmallerMd && (
        <div style={{ paddingBottom: '1rem' }}>
          <BackButton />
        </div>
      )}
      <FormHeader title={t('apartmentMediaForm.title')} subtitle={t('apartmentMediaForm.subTitle')} />
      {apartment && (
        <Grid container rowSpacing={4} className={'form-with-navigation'}>
          <Grid item xs={12}>
            <InputLabel>{t('upload.images')}</InputLabel>
            <ApartmentMediaUpload apartment={apartment} />
          </Grid>

          <Grid item xs={12}>
            <SplitLine size={'small'} />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{t('upload.layout')}</InputLabel>
            <ApartmentLayoutUpload apartment={apartment} />
          </Grid>

          <Grid item xs={12}>
            <SplitLine size={'small'} />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{t('upload.documents')}</InputLabel>
            <ApartmentDocumentUpload apartment={apartment} />
          </Grid>

          <Grid item xs={12}>
            <SplitLine size={'small'} />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel>{t('urlLinks')}</InputLabel>
            <ApartmentLinksUpload apartment={apartment} />
          </Grid>

          <Grid item xs={12}>
            <SplitLine size={'small'} />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel>{t('bookVirtualTour.label')}</InputLabel>
            <button type={'button'} onClick={() => setIsCalendlyOpen(true)} style={{ height: '100%' }}>
              <Card
                headline={v('iconBox.3dTour.title')}
                text={v('iconBox.3dTour.text')}
                iconSrc={v('iconBox.3dTour.iconSrc')}
                iconAltText={v('iconBox.3dTour.altText')}
              />
            </button>
          </Grid>
        </Grid>
      )}
      <FormNavigation
        primaryAction={handleSubmit}
        submitButtonText={isTunnelView ? t('saveAndContinue') : t('save')}
        showSecondaryButtonMobile={isTunnelView}
        buttonSubmitMode={SUBMIT_BUTTON_MODE.NONE}
        disabled={false}
        secondaryButtonAction={() => navigate(-1)}
        secondaryButtonColor={TEXT_COLOR.TEXT_COLOR_ACCENT}
        secondaryButtonText={t('back')}
      />
    </div>
  );
};

export default ApartmentMediaForm;

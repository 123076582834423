import { FC, useEffect, useState } from 'react';
import { CircularProgress, Typography, Box, Grid, Fade, Grow } from '@mui/material';
import Confetti from 'react-confetti';
import useWindowSize from 'core/hook/windowsize.hook';
import CTAButton from 'component/atoms/Buttons/CTAButton';
import { useTunnelContext } from 'core/context/create-tenant-from-apartment-apply-tunnel.context';
import LOCAL_STORAGE_KEYS from 'core/enum/local-storage.enum';
import useApartment from 'core/hook/apartment.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faHourglass, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { wohnsinnServices } from 'App';

const CheckMatchingHouseHoldToApartmentStep = () => {
  const [loading, setLoading] = useState(true);
  const { goToNextStep, household } = useTunnelContext();
  const [step, setStep] = useState(0);
  const [personStatus, setPersonStatus] = useState<boolean>(null);
  const [petsStatus, setPetsStatus] = useState<boolean>(null);
  const [wgStatus, setWgStatus] = useState<boolean>(null);
  const [wbsStatus, setWbsStatus] = useState<boolean>(null);
  const { apartment } = useApartment();
  const desiredTenant = apartment?.desiredTenant;
  const { mixpanelTrackingService } = wohnsinnServices;

  const checkPersons = () => {
    if (desiredTenant?.numberOfPeopleMovingIn) {
      setPersonStatus(household?.numberOfPeopleMovingIn <= desiredTenant?.numberOfPeopleMovingIn);
    } else {
      return setPersonStatus(true);
    }
  };

  const checkPets = () => {
    if (desiredTenant?.arePetsAllowed === false) {
      setPetsStatus(household?.arePetsAllowed === false);
    } else {
      setPetsStatus(true);
    }
  };

  const checkWg = () => {
    if (desiredTenant?.isSharedUsagePossible === false) {
      setWgStatus(household?.isSharedUsagePossible === false);
    } else {
      setWgStatus(true);
    }
  };

  const checkWbs = () => {
    if (desiredTenant?.isHousingEntitlementCertificateMandatory === true) {
      setWbsStatus(household?.canBeSubsidized === true);
    } else {
      setWbsStatus(true);
    }
  };
  const householdConfirmed = () => wgStatus && wbsStatus && personStatus && petsStatus;

  useEffect(() => {
    let timer: any;
    if (apartment && household) {
      timer = setTimeout(() => {
        if (step === 0) {
          checkPersons();
          setStep(1);
        } else if (step === 1) {
          checkPets();
          setStep(2);
        } else if (step === 2) {
          checkWg();
          setStep(3);
        } else if (step === 3) {
          checkWbs();
          setStep(4);
          setLoading(false);
          mixpanelTrackingService.trackEvent('CheckedHouseHoldToApartment', householdConfirmed);
        }
      }, 1000);
    }

    return () => clearTimeout(timer);
  }, [step, apartment, household]);

  const { width, height } = useWindowSize();

  const onClickHandler = () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.TENANT_HOUSEHOLD, JSON.stringify(household));
    goToNextStep();
  };

  const Item: FC<{ status: boolean; text: string }> = ({ status, text }) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: 12,
          gap: 12,
          color: status === null ? 'gray' : status ? 'green' : 'red',
          transition: 'color 0.5s ease',
        }}
      >
        <FontAwesomeIcon
          icon={status === null ? faHourglass : status ? faCheckCircle : faTimesCircle}
          size="xl"
          color={status === null ? 'grey' : status ? 'green' : 'red'}
          style={{ transition: 'color 0.5s ease' }}
        />
        {status === null ? `Prüfe ${text}...` : status ? `${text} erfüllt.` : `${text} nicht erfüllt.`}
      </div>
    );
  };

  return (
    <Grid container maxWidth={{ xs: '100%', md: 500 }} marginX={'auto'} paddingTop={{ xs: 6, md: 8 }} spacing={0}>
      <Grid item xs={12}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />

            <Typography variant="h6" align="center" marginTop={3}>
              Wir überprüfen, ob dein Profil die Anforderungen des Vermieters erfüllt...
            </Typography>
          </Box>
        ) : (
          <>
            <Fade in={!loading} timeout={1000}>
              <Box>
                {householdConfirmed() ? <Confetti width={width} height={height} /> : null}
                <Typography variant="h4" align="center" marginTop={2} fontWeight="bold">
                  {householdConfirmed() ? 'Glückwunsch! ' : 'Tut uns leid!'}
                </Typography>
                <Typography variant="h6" align="center" marginTop={3}>
                  {householdConfirmed()
                    ? 'Dein Profil passt zu den gewünschten Angaben der Wohnung 🥳'
                    : 'Leider passt dein Profil nicht auf diese Wohnung 🥲'}
                </Typography>
              </Box>
            </Fade>
          </>
        )}

        <Box marginTop={5}>
          <Fade in={step >= 0} timeout={500}>
            <div>{step >= 0 ? <Item status={personStatus} text={'Personenanzahl'} /> : null}</div>
          </Fade>
          <Fade in={step >= 1} timeout={500}>
            <div>{step >= 1 ? <Item status={petsStatus} text={'Haustiere'} /> : null}</div>
          </Fade>
          <Fade in={step >= 2} timeout={500}>
            <div>{step >= 2 ? <Item status={wgStatus} text={'WG-Bedingung'} /> : null}</div>
          </Fade>
          <Fade in={step >= 3} timeout={500}>
            <div>{step >= 3 ? <Item status={wbsStatus} text={'WBS-Bedingung'} /> : null}</div>
          </Fade>
        </Box>

        {!loading && (
          <Box marginTop={4} textAlign="center">
            <Grow in={!loading} timeout={1000}>
              <div>
                <CTAButton
                  buttonText={householdConfirmed() ? 'Mit Bewerbung fortfahren' : 'Zeige mir passende Wohnungen'}
                  expand={'block'}
                  rounded={false}
                  onClick={onClickHandler}
                />
              </div>
            </Grow>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default CheckMatchingHouseHoldToApartmentStep;

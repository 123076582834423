import { ROUTES } from '../routes';
import ActivateAccountView from '../../../view/static/registration/ActivateAccountView';
import CompareWithCheck24View from '../../../view/static/move/CompareWithCheck24View';
import CreateRegistrationView from '../../../view/static/registration/CreateRegistrationView';
import CreateSchufaView from '../../../view/static/move/CreateSchufaView';
import NotFoundView from '../../../view/static/NotFoundView';
import OrganizationPortfolioView from '../../../view/static/organization/OrganizationPortfolioView';
import PasswordResetConfirmationView from '../../../view/static/PasswordResetSuccessView';
import RedirectView from '../../../view/static/RedirectView';
import SearchView from '../../../view/static/SearchView';
import ServicesView from '../../../view/static/ServicesView';
import ShareApartmentView from '../../../view/static/ShareApartmentView';
import UnsubscribeView from '../../../view/static/UnsubscribeView';
import { IProtectedRouteProps } from '../../../AppRouter';
import InvitationRegistrationView from '../../../view/static/registration/InvitiationRegistrationView';
import SearchTunnelView from '../../../view/static/SearchTunnelView';
import TunnelRegistrationView from '../../../view/static/registration/TunnelRegistrationView';
import CreateTenantFromApartmentApplyTunnel from 'view/user/registration/CreateTenantFromApartmentApplyTunnel';

// These routes are always available if not in maintenance mode
export const STATIC_ROUTES: IProtectedRouteProps[] = [
  { path: ROUTES.staticRoutes.move.compareCheck24.path, Component: CompareWithCheck24View },
  { path: ROUTES.staticRoutes.move.createSchufa.path, Component: CreateSchufaView },
  { path: ROUTES.staticRoutes.move.overview.path, Component: ServicesView },
  { path: ROUTES.staticRoutes.notFound.path, Component: NotFoundView },
  { path: ROUTES.staticRoutes.organization.portfolio.apartment.path, Component: ShareApartmentView },
  { path: ROUTES.staticRoutes.organization.portfolio.path, Component: OrganizationPortfolioView },
  { path: ROUTES.staticRoutes.passwordResetConfirmation.path, Component: PasswordResetConfirmationView },
  { path: ROUTES.staticRoutes.redirect.path, Component: RedirectView },
  { path: ROUTES.staticRoutes.redirect.path, Component: RedirectView, params: ['redirectType'] },
  { path: ROUTES.staticRoutes.registration.createRegistration.path, Component: CreateRegistrationView },
  { path: ROUTES.staticRoutes.registration.createRegistration.referrer, Component: CreateRegistrationView },
  { path: ROUTES.staticRoutes.registration.activateAccount.path, Component: ActivateAccountView },
  { path: ROUTES.staticRoutes.registration.tunnel.path, Component: TunnelRegistrationView },
  { path: ROUTES.staticRoutes.search.path, Component: SearchView },
  { path: ROUTES.staticRoutes.searchTunnel.path, Component: SearchTunnelView },
  {
    path: ROUTES.staticRoutes.createTenantFromApartmentApplyTunnel.path,
    Component: CreateTenantFromApartmentApplyTunnel,
  },
  { path: ROUTES.staticRoutes.services.path, Component: ServicesView },
  { path: ROUTES.staticRoutes.shareApartment.path, Component: ShareApartmentView },
  { path: ROUTES.staticRoutes.unsubscribe.path, Component: UnsubscribeView },
  { path: ROUTES.staticRoutes.invitation.path, Component: InvitationRegistrationView },
];

import SearchTunnelStepLayout from 'component/organisms/searchTunnel/SearchTunnelStepLayout';
import { SUBMIT_BUTTON_MODE } from 'core/enum/submit-button-mode.enum';
import TextInput from 'component/atoms/formElement/InputText';
import AuthProviderLoginButtons from 'component/molecules/AuthProviderLoginButtons';
import AcceptPrivacyInfoText from 'component/atoms/AcceptPrivacyInfoText';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { USER_CREDENTIALS_FORM_SCHEMA } from 'view/static/registration/CreateRegistrationView';
import { useContext, useState } from 'react';
import { AUTH_PROVIDER, ICheckIfUserExistsResponse, REGISTRATION_REFERRER, USER_TYPE } from '@wohnsinn/ws-ts-lib';
import FB_FUNCTION_URLS from 'core/const/fb-function-names';
import { ROUTES } from 'core/const/routes';
import SnackBarContext from 'core/context/snackbar.context';
import { useNavigate } from 'react-router-dom';
import { wohnsinnServices } from 'App';
import TenantFilterParamsContext from 'core/context/tenant-filter-params.context';
import { useTranslation } from 'react-i18next';
import { useTunnelContext } from 'core/context/create-tenant-from-apartment-apply-tunnel.context';

const StartSignInStep = () => {
  const { handleSnackBar } = useContext(SnackBarContext);
  const { t } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { household, setHousehold, goToNextStep, goToPreviousStep } = useTunnelContext();
  const [isCreatingAccount, setIsCreatingAccount] = useState<boolean>(false);
  const { tenantFilterParams } = useContext(TenantFilterParamsContext);
  const navigate = useNavigate();
  const { firebaseFunctionsService, registrationRequestService } = wohnsinnServices;
  const [buttonSubmitMode, setButtonSubmitMode] = useState<SUBMIT_BUTTON_MODE>(SUBMIT_BUTTON_MODE.NONE);
  const { control, handleSubmit, watch, formState } = useForm<{
    email: string;
    createLandlordAccount: boolean;
  }>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(USER_CREDENTIALS_FORM_SCHEMA),
    defaultValues: {
      email: undefined,
      createLandlordAccount: false,
    },
  });

  const handleFormSubmit = async () => {
    setButtonSubmitMode(SUBMIT_BUTTON_MODE.SUBMITTING);
    const email = watch().email;

    try {
      // Check if account with given email exists
      const response: { data: ICheckIfUserExistsResponse } = (await firebaseFunctionsService.callFbFunction(
        FB_FUNCTION_URLS.user.checkIfUserExists,
        {
          params: { email },
        }
      )) as { data: ICheckIfUserExistsResponse };

      // if no account exists, create a user registration request
      if (!response.data.userExists) {
        setIsCreatingAccount(true);
        await registrationRequestService.createUserRegistrationRequest(
          email,
          USER_TYPE.TENANT,
          REGISTRATION_REFERRER.NONE,
          tenantFilterParams
        );

        // show cool ui
        setTimeout(() => {
          setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
          navigate(r(ROUTES.staticRoutes.registration.activateAccount.path).replace(':email', email));
        }, 1500);
      } else {
        // if email already exists, check an account provider and output info message
        switch (response.data.provider) {
          case AUTH_PROVIDER.PASSWORD:
            handleSnackBar('toast.signIn.emailAccountExistsAlready', 'info');
            break;
          case AUTH_PROVIDER.GOOGLE:
            handleSnackBar('toast.signIn.googleAccountExistsAlready', 'info');
            break;
          default:
            handleSnackBar('toast.signIn.emailAccountExistsAlready', 'info');
        }
        setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
      }
    } catch (e) {
      console.error('e', e);
      handleSnackBar('toast.signIn.unknown', 'info');
      setButtonSubmitMode(SUBMIT_BUTTON_MODE.NONE);
    }
  };

  return (
    <SearchTunnelStepLayout
      formId={'START_SIGN_IN_STEP'}
      control={control}
      disabled={buttonSubmitMode === SUBMIT_BUTTON_MODE.SUBMITTING || !formState.isValid}
      buttonSubmitMode={buttonSubmitMode}
      onSubmit={handleSubmit(handleFormSubmit, console.error)}
      title={'Wie lautet deine Email?'}
      backAction={() => console.log('todo')}
    >
      <TextInput label={t('email.label')} name={'email'} type={'email'} required />

      <AuthProviderLoginButtons
        buttonText={undefined}
        isRegistration
        disabled={false}
        createLandlordAccount={false}
        setButtonSubmitMode={setButtonSubmitMode}
      />

      <AcceptPrivacyInfoText />
    </SearchTunnelStepLayout>
  );
};

export default StartSignInStep;
